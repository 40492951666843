<template>
  <div>
    <div v-if="viewEntered">
      <div v-if="customerDetailsEdit?.name" class="branch-form">
        <p v-if="!isB2cCustomer">{{ $t('newcustomer.main_brand') }}</p>
        <ion-chip v-else class="b2c-label-chip">B2C</ion-chip>
      </div>
      <!-- Company Name -->
      <div class="card">
        <ion-card>
          <div class="avatar">
            <div class="avatar-display">
              <ion-slides :options="slideOpts">
                <ion-slide v-for="(data, index) in listImage" :key="index">
                  <div v-if="!data.isDefaultImage" style="width: 100%; height: 224px">
                    <td-image
                      style="width: 100%; height: 224px"
                      :image="data.image"
                      :imagePath="IMAGE_PATH.PRODUCT"
                    ></td-image>
                  </div>
                  <div
                    v-if="data.isDefaultImage"
                    class="image-default"
                    :style="`background: ${data.theme.background}; color:${data.theme.color}`"
                  >
                    {{
                      getInitialName(
                        customerDetailsEdit?.name ? customerDetailsEdit?.name : customerDetailsEdit?.branch
                      )
                    }}
                  </div>
                </ion-slide>
              </ion-slides>
            </div>

            <ion-button
              class="avatar-edit"
              @click="$emit('handleOpenModalUploadImage')"
              :disabled="!allowEditB2CCustomer"
            >
              <ion-icon :icon="createOutline" />
              {{ $t('newcustomer.edit_image') }}
            </ion-button>
          </div>
          <ion-card-header>
            <ion-card-subtitle v-show="!isB2cCustomer">{{
              $t('newcustomer.company_name')
            }}</ion-card-subtitle>
            <div class="title">
              <ion-card-title>{{
                customerDetailsEdit?.name ? customerDetailsEdit?.name : customerDetailsEdit?.branch
              }}</ion-card-title>
              <ion-img :src="halalUrl" v-if="customerDetailsEdit?.halal_products"></ion-img>
            </div>
          </ion-card-header>
          <ion-card-content>
            <ion-card-subtitle>{{ $t('newcustomer.profile') }}</ion-card-subtitle>
            <div class="profile-text">{{ customerDetailsEdit?.profile }}</div>
            <div class="d-flex flex-row pt-3">
              <div class="w-50">
                <ion-text class="ion-text-uppercase" color="text-medium"
                  ><p class="fs-12 fw-bold">{{ $t('customerPage.account_number') }}</p></ion-text
                >
                <ion-text class="profile-text" color="tertiary"
                  ><p>{{ customerDetailsEdit?.account_number }}</p></ion-text
                >
              </div>
              <div class="w-50">
                <ion-text class="ion-text-uppercase fs-12 fw-bold" color="text-medium">{{
                  $t('credit_term')
                }}</ion-text>
                <ion-text class="profile-text" color="tertiary"
                  ><p>
                    {{ customerDetailsEdit?.customer_credit_term.displayName }}
                  </p></ion-text
                >
              </div>
            </div>
            <ion-card-subtitle>{{ $t('newcustomer.address') }}</ion-card-subtitle>
            <div class="profile-form" v-for="(data, index) in customerDetailsEdit?.addresses" :key="index">
              <div v-if="data.is_default" class="profile-text">{{ getAddressFormat(data) }}</div>
            </div>
            <ion-button
              mode="md"
              size="small"
              shape="round"
              fill="outline"
              @click="$emit('handleOpenModalBusiness')"
              :disabled="!allowEditB2CCustomer"
              >{{
                isB2cCustomer
                  ? $t('customerPage.edit_information')
                  : $t('newcustomer.edit_business_information')
              }}</ion-button
            >
          </ion-card-content>
        </ion-card>
      </div>
      <!-- Person In Charge -->
      <div class="card">
        <ion-card>
          <ion-card-header>
            <ion-card-subtitle class="delivery_time">{{
              $t('newcustomer.person_in_charge')
            }}</ion-card-subtitle>
          </ion-card-header>
          <ion-card-content>
            <div v-for="(person, index) in sortListShowPersonInCharge" :key="index">
              <div v-if="person" class="form-person">
                <ion-text v-if="mobilePhone === person.phone || mobilePhone === person.mobile"
                  >{{
                    `${person.first_name}${person.last_name ? ' ' + person.last_name : ''} `
                  }}(You)</ion-text
                >
                <ion-text v-else
                  >{{ `${person.first_name}${person.last_name ? ' ' + person.last_name : ''} ` }}<br />{{
                    person.phone ? `(+${getPhoneNumber(person.phone || person.mobile)})` : '-'
                  }}</ion-text
                >
                <ion-text :class="person.position === 'Key Contact' ? 'text-primary' : ''">{{
                  person.position || 'Sub-Buyer'
                }}</ion-text>
              </div>
            </div>
            <ion-button
              v-show="!isB2cCustomer"
              mode="md"
              size="small"
              shape="round"
              fill="outline"
              @click="$emit('handleOpenModalPersonInCharge')"
              :disabled="!allowEditB2CCustomer"
              >{{ $t('newcustomer.edit_person_in_charge') }}</ion-button
            >
          </ion-card-content>
        </ion-card>
      </div>
      <!-- Default Delivery Time -->
      <div class="card" v-show="!isB2cCustomer">
        <ion-card>
          <ion-card-header>
            <ion-card-subtitle class="delivery_time">{{
              $t('newcustomer.default_delivery_time')
            }}</ion-card-subtitle>
          </ion-card-header>
          <ion-card-content>
            <div v-for="(workingDay, index) in filterWorkingDays" :key="index">
              <div class="form-delivery-time">
                <ion-text>{{ workingDay.day_name }}</ion-text>
                <ion-text>{{ displayWorkingTime(workingDay) }}</ion-text>
              </div>
            </div>
            <ion-button
              mode="md"
              size="small"
              shape="round"
              fill="outline"
              @click="$emit('handleOpenModalEditDeliveryTime')"
              :disabled="!allowEditB2CCustomer"
              >{{ $t('newcustomer.edit_delivery_time') }}</ion-button
            >
          </ion-card-content>
        </ion-card>
      </div>
      <!-- Payment Card Cuisine -->
      <div class="card">
        <ion-card>
          <ion-card-header>
            <ion-card-subtitle class="delivery_time">{{ $t('cuisine_preference') }}</ion-card-subtitle>
          </ion-card-header>
          <ion-card-content>
            <div>
              <div class="form-delivery-time">
                <ion-text>{{ caculateCuisineShow }}</ion-text>
              </div>
            </div>
            <ion-button
              mode="md"
              size="small"
              shape="round"
              fill="outline"
              @click="setOpenModalCuisine(true)"
              :disabled="!allowEditB2CCustomer"
              >{{ caculateCuisineShow.length > 0 ? $t('edit_cusine') : $t('add_cuisine') }}</ion-button
            >
          </ion-card-content>
        </ion-card>
      </div>
    </div>
  </div>
  <ion-loading
    mode="ios"
    :is-open="isOpenRefLoading"
    cssClass="my-custom-class"
    :message="`${$t('please_wait')}. . .`"
    @didDismiss="setOpenLoading(false)"
  >
  </ion-loading>
  <!-- Modal Cuisine -->
  <ion-modal :is-open="isOpenModalCuisine" backdropDismiss="false">
    <CuisinePreferences
      :isCheckCustomerDetailsPage="true"
      :cuisinesCustomerDetails="dataCuisine"
      :customerId="customerDetailsEdit?.id"
      :countryId="countryId"
      @later="setOpenModalCuisine(false)"
      @apply="handleSaveCuisine"
    />
  </ion-modal>
</template>

<script>
import halal from '@/assets/images/b2b/home/halal.svg';
import { apolloClient } from '@/main';
import { IMAGE_PATH } from '@/modules/sale/constants/index';
import { formatTime, getAddressFormat, getDefaultCountryCode } from '@/modules/sale/services/libs/helper';
import { handleRandomBackground } from '@/modules/shared/utils';
import { handleCaculateCuisineShow } from '@/modules/shared/utils/';
import { createOutline } from 'ionicons/icons';
import { defineAsyncComponent, ref } from 'vue';

import { USER_PERMISSION_KEYS } from '@/modules/sale/constants';
import { GetCuisines } from '@/modules/sale/services/graphql';
import { allUserPermissions } from '@/services/shared/graphql';

export default {
  props: ['customerDetailsEdit', 'isGetListCardWhenBackBtn', 'mobilePhone', 'countryId'],
  components: {
    CuisinePreferences: defineAsyncComponent(() =>
      import('@/modules/shared/views/cuisine-preferences/index.vue')
    )
  },
  emits: [
    'closeModal',
    'handleOpenModalBusiness',
    'handleOpenModalEditDeliveryTime',
    'handleOpenModalUploadImage',
    'handleOpenModalPersonInCharge',
    'handleOpenModalPayment',
    'handleSendListShowPersonInCharge',
    'isKeyContact'
  ],
  setup() {
    const slideOpts = {
      initialSlide: 0,
      speed: 400
    };
    const isOpenRefLoading = ref(false);
    const setOpenLoading = async (state) => (isOpenRefLoading.value = state);
    const dataCuisine = ref([]);
    //modal cuisine
    const isOpenModalCuisine = ref(false);
    const setOpenModalCuisine = (state) => (isOpenModalCuisine.value = state);
    return {
      isOpenRefLoading,
      setOpenLoading,
      slideOpts,
      getAddressFormat,
      halal,
      createOutline,
      dataCuisine,
      handleCaculateCuisineShow,
      isOpenModalCuisine,
      setOpenModalCuisine,
      IMAGE_PATH
    };
  },

  computed: {
    filterWorkingDays() {
      return (
        (this.customerDetailsEdit &&
          this.customerDetailsEdit.working_hours &&
          this.customerDetailsEdit.working_hours.filter((item) => item.active)) ||
        []
      );
    },
    sortListShowPersonInCharge() {
      const data = this.customerDetailsEdit.persons;
      const findKeyContact = data?.find((item) => item.position === 'Key Contact');
      if (findKeyContact) {
        const key = data.indexOf(findKeyContact);
        data.splice(key, 1);
        data.unshift(findKeyContact);
        return data;
      } else {
        return data;
      }
    },
    caculateCuisineShow() {
      return handleCaculateCuisineShow(this.dataCuisine);
    },
    allowEditB2CCustomer() {
      const isAllowed = this.checkPermission(USER_PERMISSION_KEYS.CUSTOMER_EDIT_B2C);
      if (this.customerDetailsEdit?.is_b2c) {
        return isAllowed;
      }
      return true;
    },
    isB2cCustomer() {
      return !!this.customerDetailsEdit?.is_b2c;
    }
  },
  async mounted() {
    const findKeyContact = this.customerDetailsEdit.persons?.find((item) => item.position === 'Key Contact');
    findKeyContact ? (this.isKeyContact = true) : (this.isKeyContact = false);
    this.$emit('isKeyContact', this.isKeyContact);
    await this.setOpenLoading(true);
    this.halalUrl = halal;
    await Promise.all([this.handleGetCuisine(), this.handleGetImage(), this.getAllUserPermissions()]);
    await this.setOpenLoading(false);
    this.viewEntered = true;
    this.$emit('handleSendListShowPersonInCharge', this.sortListShowPersonInCharge);
  },
  data() {
    return {
      isOpenModalBusinessInformation: false,
      isDefaultImage: false,
      theme: [],
      listCardByCustomerId: [],
      halalUrl: '',
      viewEntered: false,
      listImage: [],
      isKeyContact: false,
      permissions: []
    };
  },

  methods: {
    displayWorkingTime({ open_hour, open_minute, close_hour, close_minute }) {
      return `${formatTime(open_hour)}:${formatTime(open_minute)} - ${formatTime(close_hour)}:${formatTime(
        close_minute
      )}`;
    },
    getPhoneNumber(params) {
      if (!params) return '';
      const phone =
        params.indexOf(' ') > -1
          ? params.split(' ')[1]
          : params.length > 8
          ? params.substring(2, params.length)
          : params;
      const countryCode =
        params.indexOf(' ') > -1
          ? params.split(' ')[0]
          : params.length > 8
          ? params.substring(0, 2)
          : getDefaultCountryCode();
      const phoneNumber = countryCode + ' ' + phone;
      return phoneNumber;
    },
    async handleGetImage() {
      for (const value of this.customerDetailsEdit.customerImage) {
        this.listImage.push({
          isDefaultImage: value.image ? true : false,
          theme: value.image ? handleRandomBackground() : '',
          image: value.image ? '' : value.image
        });
      }
      if (this.listImage.length === 0) {
        this.listImage.push({
          isDefaultImage: true,
          theme: handleRandomBackground()
        });
      }
    },
    getInitialName(name) {
      let initials = '';
      let names = name.split(' ');
      let nameCount = names.length > 2 ? 2 : names.length;
      for (let i = 0; i < nameCount; i++) {
        if (names[i]) {
          initials += names[i][0].toUpperCase();
        }
      }
      return initials;
    },
    async handleGetCuisine() {
      const { data } = await apolloClient.query({
        query: GetCuisines,
        variables: {
          customerId: this.customerDetailsEdit.id,
          countryId: this.countryId
        }
      });
      this.dataCuisine = data.getCuisines;
    },
    async getAllUserPermissions() {
      const response = await apolloClient.query({
        query: allUserPermissions
      });
      this.permissions = response?.data?.allUserPermissions?.permissions || [];
    },
    checkPermission(code = '') {
      return this.permissions.some((i) => i.code === code);
    },
    async handleSaveCuisine() {
      await this.handleGetCuisine();
      this.setOpenModalCuisine(false);
    }
  }
};
</script>

<style lang="scss" src="@/assets/css/newdesign.scss" scoped></style>
<style lang="scss" src="./styles/ModalCustomerDetails.scss" scoped></style>
